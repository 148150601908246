import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { AlertDisplay, OAuthRequestDialog, SignInPage, SignInProviderConfig } from '@backstage/core-components';
import { IdentityApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from '@backstage/plugin-catalog';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechDocsIndexPage, TechDocsReaderPage, techdocsPlugin } from '@backstage/plugin-techdocs';
import { TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { UnifiedThemeProvider } from '@backstage/theme';
import { ReleaseNotesPage } from '@internal/plugin-release-notes';
import { SelfServicePage } from '@internal/plugin-self-service';
import WebIcon from '@material-ui/icons/Web';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apis } from './apis';
import { Root } from './components/Root';
import { entityPage } from './components/catalog/EntityPage';
import { HomePage } from './components/home/HomePage';
import { searchPage } from './components/search/SearchPage';
import { caeDarkTheme, caeLightTheme } from './theme/CaeThemes';

// Define the props specifically for your customized SignInPage
interface CustomSignInPageProps {
  onSignInSuccess: (identityApi: IdentityApi) => void;
  children?: React.ReactNode;
}

const microsoftAuthProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'Microsoft Active Directory',
  message: 'Sign in to the IDP using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};

// Custom SignInPage accepting the specified props
const AppSignInPage: React.FC<CustomSignInPageProps> = ({ onSignInSuccess, children }) => {
  return (
    <SignInPage provider={microsoftAuthProvider} onSignInSuccess={onSignInSuccess}>
      {children}
    </SignInPage>
  );
};

export { AppSignInPage };

const app = createApp({
  apis,
  icons: {
    alert: WebIcon,
  },
  themes: [
    {
      id: 'cae-light-theme',
      title: 'Light Theme',
      variant: 'light',
      Provider: ({ children }) => <UnifiedThemeProvider theme={caeLightTheme} children={children} />,
    },
    {
      id: 'cae-dark-theme',
      title: 'Dark Theme',
      variant: 'dark',
      Provider: ({ children }) => <UnifiedThemeProvider theme={caeDarkTheme} children={children} />,
    },
    // {
    //   id: 'cae-test',
    //   title: 'CAE Test',
    //   variant: 'dark',
    //   Provider: ({ children }) => <UnifiedThemeProvider theme={caeTestTheme} children={children} />,
    // },
  ],
  components: {
    SignInPage: AppSignInPage,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(scaffolderPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    ;
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <TextSize />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={<CatalogGraphPage initialState={{ selectedKinds: ['Component', 'API', 'Resource', 'System', 'Domain', 'Group'], maxDepth: 1 }} />}
    />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/self-service" element={<SelfServicePage />} />
    <Route path="/release-notes" element={<ReleaseNotesPage title='Release Notes'/>} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
